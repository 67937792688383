import * as React from 'react';
import PropTypes from 'prop-types';

import PostImage from '../../assets/images/cube.png';
import * as s from './styles.module.scss';

const MAX_TEXT_LENGTH = 200;

const Post = ({ title, body, meta }) => {
  const cutText = (text) => {
    return text.length > MAX_TEXT_LENGTH
      ? text.substring(0, MAX_TEXT_LENGTH) + '...'
      : text;
  };

  return (
    <a href="/NewsPage" className={s.post}>
      <div className={s.post_content}>
        <h3 className={s.post_title}>{title}</h3>
        <p className={s.post_text}>{cutText(body)}</p>
        <div className={s.meta}>
          <p className={s.meta_date}>{meta.date}</p>
          <div className={s.meta_timeToRead}>{meta.timeToRead}</div>
        </div>
      </div>
      <img src={PostImage} className={s.post_image} alt="post" />
    </a>
  );
};

Post.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    date: PropTypes.string.isRequired,
    timeToRead: PropTypes.string.isRequired,
  }).isRequired,
};

export default Post;
