import * as React from 'react';

import Container from '../../../common/container';

import * as s from './styles.module.scss';

const Hero = () => {
  return (
    <section className={s.hero}>
      <Container>
        <h1 className={s.hero_title}>Edunomic BLOG</h1>
      </Container>
    </section>
  );
};

export default Hero;
