import * as React from 'react';

import Layout from '../../layout';
import Hero from '../../components/news/hero';
import PostList from '../../components/news/post-list';

import * as s from './styles.module.scss';

const POSTS_MOCK = [
  {
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    body:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
      'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ' +
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
      'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
    image: 'src',
    meta: {
      date: '23 Aug',
      timeToRead: '3 min',
    },
  },
  {
    title: 'Lorem',
    body:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
      'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ',
    image: 'src',
    meta: {
      date: '23 Aug',
      timeToRead: '3 min',
    },
  },
  {
    title: 'Lorem',
    body:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
      'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ',
    image: 'src',
    meta: {
      date: '23 Aug',
      timeToRead: '3 min',
    },
  },
  {
    title: 'Lorem',
    body:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
      'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ',
    image: 'src',
    meta: {
      date: '23 Aug',
      timeToRead: '3 min',
    },
  },
  {
    title: 'Lorem',
    body:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
      'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ',
    image: 'src',
    meta: {
      date: '23 Aug',
      timeToRead: '3 min',
    },
  },
];

const NewsPage = () => {
  return (
    <Layout>
      <Hero />
      <PostList className={s.newsPage_posts} posts={POSTS_MOCK} />
    </Layout>
  );
};

export default NewsPage;
