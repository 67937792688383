import PropTypes from 'prop-types';
import * as React from 'react';

import NextIcon from '../../assets/icons/next.svg';
import PrevIcon from '../../assets/icons/prev.svg';

import * as s from './styles.module.scss';

const Pagination = ({ pages, activePage, setNewPage }) => {
  const handleClick = () => {
    window.scrollTo({ top: 200, behavior: 'smooth' });
  };

  const toNext = () => {
    handleClick();
    setNewPage((prev) => prev + 1);
  };

  const toPrev = () => {
    handleClick();
    setNewPage((prev) => prev - 1);
  };

  return (
    <div className={s.container}>
      {activePage !== 1 && (
        <button className={s.switchBtn} onClick={toPrev}>
          <PrevIcon className={s.switchBtn_icon} />
        </button>
      )}
      <p>{activePage}</p>
      {activePage <= pages && (
        <button className={s.switchBtn} onClick={toNext}>
          <NextIcon className={s.switchBtn_icon} />
        </button>
      )}
    </div>
  );
};

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  setNewPage: PropTypes.func.isRequired,
};

export default Pagination;
