import React, { useMemo, useState } from 'react';
// import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Container from '../../../common/container';
import Post from '../../../common/post';
import Pagination from '../../../common/pagination';

import * as s from './styles.module.scss';

const POSTS_PER_PAGE = 4;

const PostList = ({ posts, className }) => {
  const [activePage, setActivePage] = useState(1);
  const pages = useMemo(() => posts.length / POSTS_PER_PAGE, [posts]);

  return (
    <Container>
      <div className={cn(s.postList, className)}>
        {posts
          ?.slice(
            POSTS_PER_PAGE * (activePage - 1),
            POSTS_PER_PAGE * activePage
          )
          .map((post) => (
            <Post
              key={post.title}
              title={post.image}
              body={post.body}
              image={post.image}
              meta={post.meta}
            />
          ))}
      </div>
      {posts?.length > POSTS_PER_PAGE && (
        <Pagination
          activePage={activePage}
          setNewPage={setActivePage}
          pages={pages}
        />
      )}
    </Container>
  );
};

PostList.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }).isRequired
  ),
  className: PropTypes.node,
};

export default PostList;

// export const query = graphql``;
